import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ApiResponse } from './models/api-response.models'
import { FleetEmailNotificationPreference } from '@boosterfuels/types/types-ts'

@Injectable()
export class FleetEmailNotificationPreferenceApi {
  constructor(private http: HttpClient) {}

  find(): Observable<ApiResponse<FleetEmailNotificationPreference>> {
    return this.http.get<ApiResponse<FleetEmailNotificationPreference>>(
      '/FleetEmailNotificationPreference',
      {
        params: new HttpParams({
          fromObject: { sort: JSON.stringify({ sortRank: 1 }) },
        }),
      }
    )
  }
}
